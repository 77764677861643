<template>
  <div>
    <v-row>
      <v-col cols="auto">
        <v-btn color="indigo lighten-2 white--text" small @click="fetchdata">
          Recargar</v-btn
        >
      </v-col>
      <v-col cols="6" md="2" v-if="chartdata.length > 5">
        <v-select class="filtergraph filter" outlined v-model="cantidadDatos" :items="listaResultado" label="Mostrar últimos"></v-select>
      </v-col>
      <v-col class="d-none d-md-inline ml-5 mr-5 mt-1">
        <v-row>
          <v-col v-for="state in status" :key="state.value" cols="12" sm="auto">
            <p class="text-body-2">
              <v-icon class="pr-2" :color="state.color">mdi-circle</v-icon
              >{{ state.name }}
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div v-if="empty">
      <v-col
        cols="12"
        class="d-flex align-center flex-column message-no-data pa-0"
      >
        <v-icon size="50">mdi-chart-timeline-variant</v-icon>
        <h3>No existen datos que mostrar</h3>
      </v-col>
    </div>
    <v-row :class="empty ? 'nodisplay' : ''">
      <v-col cols="12" md="7" lg="9" class="pa-0">
        <div ref="chartdiv" class="graph1"></div>
      </v-col>
      <v-col cols="12" md="5" lg="3">
        <v-card class="mt-5">
          <v-card-title class="pb-0">
            <h5>Últimas lecturas</h5>
          </v-card-title>
          <v-card-text class="text--primary pa-4">
            <div>
              <div
                class="text-body-2"
                v-for="(val, index) in chartdata_table"
                :key="index"
              >
                <span>
                  <b class="color-text-glya mr-2">{{ index + 1 }}.</b>
                  <v-chip outlined color="primary" small class="pa-0 pl-3 pr-3">
                    <span v-if="presion"
                      >{{ val.value }}/{{ chartdata_table2[index].value }}
                      {{ val.unit }}</span
                    >
                    <span v-else>{{ val.value }} {{ val.unit }}</span>
                  </v-chip>
                </span>
                <br />
                <span
                  ><v-icon class="mr-1" small
                    >mdi-calendar-clock-outline</v-icon
                  >
                  {{ val.date | formatDate }}</span
                >
                <v-divider class="mt-1 mb-2"></v-divider>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="delete_dialog"
      max-width="500px"
      transition="dialog-transition"
      :overlay="true"
    >
      <v-card>
          <v-card-title primary-title>
            Esta seguro que desea eliminar esta medición?
          </v-card-title>

          <v-card-text>
        <v-text-field
          outlined
          :rules="[rules.reason_counter]"
          v-model="delete_reason"
          label="Cual es el motivo que desea eliminar esta medición"
        ></v-text-field>


        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn @click="delete_dialog=false" class="pl-5 pr-5 gray--text" depressed color="default" small >Cancelar <v-icon right dark > mdi-close</v-icon> </v-btn>
          <v-btn @click="delete_measurement" class="pl-5 pr-5 white--text" depressed color="red" small :disabled="delete_reason.length<=15 || delete_reason.length>140">Eliminar Medición<v-icon right dark > mdi-check</v-icon></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import AxiosServices from "@/services/AxiosServices";



export default {
  name: "patientMeasureChart",
  data() {
    return {
      empty: true,
      chartdata: [],
      chartdata2: [],
      status: [
        { color: "primary", name: "Prioridad Baja" },
        { color: "yellow darken-3", name: "Prioridad Media" },
        { color: "error", name: "Prioridad Alta" },
      ],
      //ESTO NO DEBERIA SER: TENEMOS QUE BUSCAR UNA SOLUCION GENERICA PARA ELEMENTOS CLINICOS COMPUESTOS
      presion: this.type == "3" || this.type == "4",
      errorcompuesto: false,
      total_objetos : null,
      paso : 10,
      listaResultado: [],
      cantidadDatos: 5,
      delete_dialog:false,
      delete_reason:"",
      measurement_to_delete:null,
      rules:{
        reason_counter:value=>(value.length>=15 && value.length<=140) || 'Debe tener minimo 15 caracteres y maximo 140'
      }
    };
  },
  mounted() {
    this.fetchdata();
    this.graph2();
  },
  watch: {
    cantidadDatos(newValue) {
      let arr=this.chartdata
      if (arr.length>0) {  
        let date1=arr[arr.length-1].date
        let date2 = null
        if (arr.length >= newValue) {  
        date2=arr[arr.length-newValue].date
      } else {
        date2=arr[arr.length-arr.length].date
      }
      var gap=(date1-date2)*0.1
        this.xAxis.zoomToDates(new Date(date2-gap), new Date(date1+gap));
        //this.fetchdata(newValue);
      }
    },
  },
  
  props: ["pid", "type", "load"],
  methods: {
    graph2: function () {
      const vm = this;
      let root = am5.Root.new(this.$refs.chartdiv);

      root.setThemes([am5themes_Animated.new(root)]);

      let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panY: false,
          pinchZoomX: true,
          pinchZoomY: false,
          panX: true,
          wheelY: "zoomX",
          layout: root.verticalLayout,
          maxTooltipDistance: 0,
        })
      );
      chart.leftAxesContainer.set("layout", root.verticalLayout);

      //COLORES
      let gray1 = am5.color("#888");

      // Creo X-Axis
      let xAxis = chart.xAxes.push(
        am5xy.DateAxis.new(root, {
          baseInterval: { timeUnit: "minute", count: 1 },
          renderer: am5xy.AxisRendererX.new(root, {}),
          start:-1,
          end:1          
        })
      );

      xAxis.set(
        "tooltip",
        am5.Tooltip.new(root, {
          themeTags: ["axis"],
        })
      );

      let xRenderer = xAxis.get("renderer");
      xRenderer.labels.template.setAll({
        fill: am5.color("#777"),
        fontSize: "0.8em",
      });
      //xAxis.data.setAll(data);
      xRenderer.grid.template.set("location", 0.5);
      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {
            behavior: "selectX",
          }),
          x: am5.p100,
          centerX: am5.p100,
          marginTop: 0, // this makes gap between axes
        })
      );
      let yRenderer = yAxis.get("renderer");
      yRenderer.labels.template.setAll({
        fill: am5.color("#777"),
        fontSize: "0.8em",
      });

      let tooltip_inside= am5.Tooltip.new(root, {
            getFillFromSprite: false,
            labelHTML: `
            <div>
              <span style='color:white;padding:4px;background-color:#29436e;border-radius:5px'>{valueX.formatDate('HH:mma')}</span>
              <small>{valueX.formatDate('MMM dd, yyyy')}</small> </div><div style='font-size:1.3em;text-align:center'>
                <strong>{labeltool}</strong>
                <small> {unit}</small>
                <div id="conditional-button-container"></div>
            </div>`,
      });

      tooltip_inside.adapters.add("labelHTML", (labelHTML, target) => {
        const dataItem = target.dataItem; // Obtener el dataItem del tooltip
        if (dataItem && dataItem.dataContext) {
          const context = dataItem.dataContext; // Contexto de datos (los datos asociados al punto)
          if (context.eraseble) {
            return labelHTML.replace(
              `<div id="conditional-button-container"></div>`,
              `
               <div><button class="btn-tooltip" id="{id.formatNumber('0')}" style='color:white;font-size:0.6em;padding:4px;background-color:red;border-radius:5px'>Eliminar</button></div>
              `
            );
          }
        }
        return labelHTML;
      });
      document.addEventListener("click", (event) => {
        if (event.target && event.target.classList.contains("btn-tooltip")) {
          const id = event.target.id; // Extrae el valor de data-id
          vm.open_modal_delete_measurement(id); // Pasa el id al método
        }
      });

      tooltip_inside.get("background").setAll({
        fill: am5.color(0xbdd6ff),
        fillOpacity: 0.8,
        stroke: am5.color(0x333333),
        strokeOpacity: 0.5
      });
      // Creo series
      let series1 = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "Series",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "value",
          valueXField: "date",
          fill: am5.color(0x2f95ba),
          stroke: am5.color(0x2f95ba),
          tooltip: tooltip_inside
        })
      );
      series1.get("tooltip").label.set("interactive", true);      
      series1.strokes.template.setAll({
        strokeWidth: 1,
        strokeDasharray: [3, 1],
      });

      chart.set(
        "cursor",
        am5xy.XYCursor.new(root, {
          xAxis: xAxis,
          snapToSeries: [series1],
          snapToSeriesBy: "x",

          //behavior: "selectX",
        })
      ); 

      series1.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Circle.new(root, {
            radius: 4,
            fill: gray1,
          }),
        });
      });

      series1.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Label.new(root, {
            text: "{valueY}",
            fill: gray1,
            fontSize: "0.7em",
            centerX: am5.percent(50),
            centerY: am5.percent(50),
            dx: 12,
            dy: 12,
            populateText: true,
          }),
        });
      });

      series1.data.processor = am5.DataProcessor.new(root, {
        numericFields: ["value"],
        dateFields: ["date"],
        dateFormat: "yyyy-MM-dd HH:mm:ss",
      });

      if (this.presion) {
        var yAxis2 = chart.yAxes.push(
          am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {
              behavior: "selectX",
            }),
            x: am5.p100,
            centerX: am5.p100,
            marginTop: 40,
          })
        );

        let yRenderer2 = yAxis2.get("renderer");
        yRenderer2.labels.template.setAll({
          fill: am5.color("#777"),
          fontSize: "0.8em",
        });
        let series2 = chart.series.push(
          am5xy.LineSeries.new(root, {
            name: "Series2",
            xAxis: xAxis,
            yAxis: yAxis2,
            valueYField: "value",
            valueXField: "date",
            tooltip: am5.Tooltip.new(root, {
              labelText: "{valueX.formatDate('yyyy-MM-dd')}: {valueY}",
            }),
          })
        );

        series2.bullets.push(function () {
          return am5.Bullet.new(root, {
            sprite: am5.Circle.new(root, {
              radius: 4,
              fill: gray1,
            }),
          });
        });

        series2.data.processor = am5.DataProcessor.new(root, {
          numericFields: ["value"],
          dateFields: ["date"],
          dateFormat: "yyyy-MM-dd HH:mm:ss",
        });
        //xAxis.set("layer", 50);
        series2.bullets.push(function () {
          return am5.Bullet.new(root, {
            sprite: am5.Label.new(root, {
              text: "{valueY}",
              fill: gray1,
              fontSize: "0.7em",
              centerX: am5.percent(50),
              centerY: am5.percent(50),
              dx: 12,
              dy: 12,
              populateText: true,
            }),
          });
        });
        this.series2 = series2;
        this.yAxis2 = yAxis2;
      }
      //series1.data.setAll(data);

      //chart.set("scrollbarX", am5.Scrollbar.new(root, { orientation: "horizontal" }));

      // Add cursor
      //chart.set("cursor", am5xy.XYCursor.new(root, {}));

      this.root = root;
      this.series1 = series1;
      this.xAxis = xAxis;
      this.yAxis = yAxis;
    },
    open_modal_delete_measurement:function(id) {
      this.delete_dialog = true
      this.measurement_to_delete=id
      this.delete_reason=""
     //console.log(id);
    },
    delete_measurement: function(){
      AxiosServices.patientMesarumentsUpdate(this.pid, this.measurement_to_delete, {measurement:{status:1,comment:this.delete_reason}}).then(res=>{
        this.fetchdata()
        this.delete_dialog=false
        console.log(res);
        
      }).catch(err=>{
        console.log(err);
      })
    },
    fetchdata: async function () {
      if (this.presion) {
        AxiosServices.patientMeasurementsList(this.pid, 3).then((res) => {
          let data3 = res.data.data;
          AxiosServices.patientMeasurementsList(this.pid, 4).then((res) => {
            let data4 = res.data.data;
            if ((data4.length == data3.length) && data4.length > 0 ) {
              this.empty = false;
              let rp1 = [];
              let rp2 = [];
              for (var c = 0; c < data4.length; c++) {
                rp1.push({
                  date: data3[c].when,
                  value: Number(data3[c].value),
                  unit: data3[c].clinical_element_unit,
                  labeltool: `${Number(data3[c].value)}/${Number(data4[c].value)}`,
                  eraseble: data3[c].eraseble
                });
                rp2.push({
                  date: data4[c].when,
                  value: Number(data4[c].value),
                  unit: data4[c].clinical_element_unit,
                  eraseble: data3[c].eraseble
                });
              }
              this.yAxis.children.push(
                am5.Label.new(this.root, {
                  text: "Sistólica \n\r mmHg",
                  rotation: -90,
                  fontSize: "0.7em",
                  y: am5.p50,
                  centerX: am5.p50,
                  x: -30,
                })
              );
              this.yAxis2.children.push(
                am5.Label.new(this.root, {
                  text: "Diastólica \n\r mmHg",
                  rotation: -90,
                  fontSize: "0.7em",
                  y: am5.p50,
                  centerX: am5.p50,
                  x: -30,
                })
              );

              this.chartdata = rp1
              this.chartdata2 = rp2
              this.series1.data.setAll(rp1)//(rp1.slice(-this.cantidadDatos));
              this.series2.data.setAll(rp2)//(rp2.slice(-this.cantidadDatos));
              this.listaSelect()
            } else {
              this.errorcompuesto = true;
            }
            for (let n of [3, 4]) {
              AxiosServices.patientAlarmsList(this.pid, n).then(
                (res) => {
                  let alarms = res.data.data;
                  console.log("alarm",alarms);

                  n == 3 ? this.yAxis.axisRanges.clear() : this.yAxis2.axisRanges.clear();
                  if (alarms.length > 0) {
                    for (let alarm of alarms) {
                      if (alarm.clinical_element_id == n)
                        this.createGraphAlarm(alarm, n == 3 ? this.yAxis : this.yAxis2);
                    }
                  }
                }
              );
            }
          });
        });
      } else {
        AxiosServices.patientMeasurementsList(this.pid, this.type).then(
          (res) => {
            let data2 = res.data.data;
            if (data2.length > 0) {
              this.empty = false;
              let rp = [];
              for (let item of data2) {
                rp.push({
                  id: item.id,
                  date: item.when,
                  value: Number(item.value),
                  unit: item.clinical_element_unit,
                  labeltool: Number(item.value),
                  eraseble: item.eraseble
                });
              }
              this.chartdata = rp;
              this.series1.data.setAll(rp)  //(rp.slice(-this.cantidadDatos));
              this.listaSelect()
              this.yAxis.children.push(
                am5.Label.new(this.root, {
                  text: data2[0].clinical_element_unit,
                  rotation: -90,
                  fontSize: "0.7em",
                  y: am5.p50,
                  centerX: am5.p50,
                  x: -30,
                })
              );
            } else {
              this.empty = true;
            }
          }
        );
        AxiosServices.patientAlarmsList(this.pid, this.type).then((res) => {
          let alarms = res.data.data;
          this.yAxis.axisRanges.clear();
          if (alarms.length > 0) {
            for (let alarm of alarms) {
              if (alarm.clinical_element_id == this.type)
                this.createGraphAlarm(alarm, this.yAxis);
            }
          }
        });
      }
      setTimeout(() => {
      this.cantidadDatos=10
    }, 300);
    },

    createGraphAlarm: function (alarm, axis) {
      let colors = {
        1: am5.color(0xeb4034),
        2: am5.color(0xeba134),
        3: am5.color(0x3F51B5),
      };
      let rangeDataItem = axis.makeDataItem({
        value: alarm.minimum_condition,
        endValue: alarm.maximum_condition,
      });
      let rangeDataend = axis.makeDataItem({
        value: alarm.maximum_condition,
      });

      let range = axis.createAxisRange(rangeDataItem);
      let rangeend = axis.createAxisRange(rangeDataend);

      range.get("grid").setAll({
        stroke: colors[alarm.priority],
        strokeOpacity: 0.5,
        strokeWidth: 2,
      });
      rangeend.get("grid").setAll({
        stroke: colors[alarm.priority],
        strokeOpacity: 0.5,
        strokeWidth: 2,
      });

      range.get("axisFill").setAll({
        fill: colors[alarm.priority],
        fillOpacity: 0.15,
        visible: true,
      });
      range.get("label").setAll({
        fill: am5.color(0x00000),
        fillOpacity: 0.25,
        //background:am5.Rectangle.new(this.root, {fill:colors[alarm.priority],fillOpacity: 1}),
        text: alarm.name_only,
        visible: true,
        location: 0.12,
        fontSize: 12,
        fontWeight: "bold",
        inside: true,
        centerX: am5.p0,
      });
      rangeend.get("label").setAll({
        fill: am5.color(0x00000),
        fillOpacity: 0.25,
        //background:am5.Rectangle.new(this.root, {fill:colors[alarm.priority],fillOpacity: 1}),
        text: alarm.name_only,
        visible: true,
        location: 0.20,
        fontSize: 12,
        fontWeight: "bold",
        inside: true,
        centerX: am5.p0,
        centerY:am5.p90
      });
    },
    listaSelect() {
      this.totalObjetos = this.chartdata.length
      console.log(this.totalObjetos);
      this.listaResultado = [5, ...Array.from({ length: (this.totalObjetos - 5) / this.paso + 1 }, (_, i) => 10 * (i + 1))];

      this.listaResultado.push(this.totalObjetos);
      if (this.listaResultado.length >= 2) {
          this.listaResultado.splice(-2, 1);
        }
    }
  },
  computed: {
    chartdata_table() {
      let data = this.chartdata
      if (data.length >= 5) {
        data = data.slice(data.length -5, data.length).reverse()
      } else {
        data = data.reverse()
      }
      return data 
    },
    chartdata_table2() {
      let data = this.chartdata2
      if (data.length >= 5) {
        data = data.slice(data.length -5, data.length).reverse()
      } else {
        data = data.reverse()
      }
      return data 
    }
  },
  beforeDestroy() {
    document.removeEventListener("click", this.globalClickListener);
    if (this.root) {
      this.root.dispose();
    }
  },
};
</script>

<style scoped>
.graph1 {
  min-height: 150px;
  height: 500px;
}

.nodisplay {
  display: none;
}

.tooltip_container {
  background-color: blue;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  /* Define el inicio de la rotación */
  100% {
    transform: rotate(360deg);
  }

  /* Define el final de la rotación */
}
</style>